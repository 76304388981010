@font-face {
  font-family: "Noto Sans JP";
  src: local("Noto Sans JP"), url("https://welvie-learning-production-assets.s3.ap-northeast-1.amazonaws.com/NotoSansJP-Regular.otf") format('opentype');;
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 600;
  src: local("Noto Sans JP"), url("https://welvie-learning-production-assets.s3.ap-northeast-1.amazonaws.com/NotoSansJP-Bold.otf") format('opentype');;
}
html, body {
	position:relative;
}
body {
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.5;
  color: var(--text-color);
  -webkit-text-size-adjust: none;
  word-break: break-all;
  overflow-x: hidden;
  height: 100%;
  @media (max-width : 767px) {
    padding-top: 60px;
  }
}

.disable-overflow {
  overflow-y: hidden !important;
}
