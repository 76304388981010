/******************************************
[reset]
******************************************/
*,
::before,
::after {
  position: relative;
  box-sizing: border-box;
}
::before, ::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
* {
  margin: 0;
  padding: 0;
}
header {
  display: block;
}
ul, ol {
  list-style: none;
}
a {
  background-color: transparent;
}
a:active, a:hover {
  outline-width: 0;
}
img {
  border-style: none;
}
input:not([type=checkbox]),
button,
textarea,
select {
  outline: 0;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input,
button,
textarea,
select {
  font: inherit;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
input::-ms-clear {
  visibility: hidden;
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  color: currentColor;
}
// textarea {
//   overflow: auto;
// }
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
}
button {
  background: none;
  overflow: visible;
}
::-moz-selection,
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
a {
  cursor: pointer;
  text-decoration: none;
  &:hover, &:focus, &:active, &:visited {
    outline: none;
    box-shadow: none;
    color: inherit;
  }
}
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}
div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}