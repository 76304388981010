:root {
  --primary-color: #EF858C;
  --primary-light-color:  #F5F4F2;
  --primary-10-color: rgba(239, 133, 140, 0.1);
  --white-20-color: rgba(255, 255, 255, 0.2);
  --white-30-color: rgba(255, 255, 255, 0.3);
  --white-70-color: rgba(255, 255, 255, 0.7);
  --white-color: #fff;
  --red-color: #D22B2B;
  --text-color: #282828;
  --text2-color: #727171;
  --placeholder-color: #999999;
  --disabled-color: #DFDFDF;
  --border-color: #EEEEEE;
  --success-color: #0CA1AA;
  --error-color: #C00202;
  --black-color: #2F2F2F;
  --black-70-color: rgba(47, 47, 47, 0.7);
  --box-shadow-color: rgba(0, 0, 0, 0.08);
  --box-shadow2-color: rgba(0, 0, 0, 0.04);
  --background-color: #FAFAFA;
  --background-black-color: #282828;
  --extra-large-max: 1200px;
  --extra-large: 1160px;
  --large-max: 768px;
  --large: 708px;
  --medium: 640px;
  --bg-orange-gradient-color: linear-gradient(180deg, rgba(250, 129, 17, 0.3) 0%, rgba(255, 170, 136, 0) 100%);
  --bg-light-cyan-color: rgba(12, 161, 170, 0.1);
  --medal-yellow-color: #FFDF8C;
  --medal-gray-color: #DADADA;
  --medal-brown-color: #EFCAB5;
  --tooltip-shadow-color: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08));
  --stepper-blue: #4F71BE;
}
//----------------------------------*\
// PARTIALS
//----------------------------------*/
@import 'partials/reset.scss';
@import 'partials/common.scss';
